
































































import { defineComponent } from "@vue/composition-api";
import { badgeVariants } from "@/lib/pfg/components/badge";
import { buttonVariants } from "@/lib/pfg/components/button";
import { cardVariants } from "@/lib/pfg/components/card";
import { inputVariants } from "@/lib/pfg/components/input";
import { labelVariants } from "@/lib/pfg/components/label";
import { skeletonVariants } from "@/lib/pfg/components/skeleton";
import { textareaVariants } from "@/lib/pfg/components/textarea";
import { cx } from "@/lib/style/cva.config";

export default defineComponent({
	props: {
		memberId: {
			type: Number,
			required: true,
		},
	},
	setup() {
		return {
			cx,
			cardVariants,
			buttonVariants,
			badgeVariants,
			labelVariants,
			inputVariants,
			textareaVariants,
			skeletonVariants,
		};
	},
});
